.contact-page .hero h1 {
  width: 75%;
}
@media (max-width: 600px) {
  .contact-page .hero h1 {
    width: 100%;
  }
}
.contact-page .info {
  transition-delay: 620ms !important;
  margin-bottom: 7.969vw;
}
@media (max-width: 1150px) {
  .contact-page .info {
    margin-bottom: 9.565vw;
  }
}
@media (max-width: 900px) {
  .contact-page .info {
    margin-bottom: 12.333vw;
  }
}
@media (max-width: 700px) {
  .contact-page .info {
    margin-bottom: 82px;
  }
}
@media (max-width: 600px) {
  .contact-page .info {
    margin-bottom: 70px;
  }
}
@media (max-width: 450px) {
  .contact-page .info {
    margin-bottom: 103px;
  }
}
@media (max-width: 375px) {
  .contact-page .info {
    margin-bottom: 158px;
  }
}
.contact-page .info .opacity {
  opacity: 0.5;
}
.contact-page .info .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-page .info .line .icons {
  display: flex;
  gap: 0.547vw;
}
@media (max-width: 1150px) {
  .contact-page .info .line .icons {
    gap: 0.609vw;
  }
}
@media (max-width: 900px) {
  .contact-page .info .line .icons {
    gap: 0.522vw;
  }
}
@media (max-width: 700px) {
  .contact-page .info .line .icons {
    gap: 6px;
  }
}
.contact-page .info .line .wrapper {
  width: 3.594vw;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 1150px) {
  .contact-page .info .line .wrapper {
    width: 4vw;
  }
}
@media (max-width: 900px) {
  .contact-page .info .line .wrapper {
    width: 3.217vw;
  }
}
@media (max-width: 700px) {
  .contact-page .info .line .wrapper {
    width: 37px;
  }
}
.contact-page .info .line .wrapper svg {
  transform: translateX(25%);
  fill: #fff;
  width: 100%;
}
@media (max-width: 700px) {
  .contact-page .info .line:last-child {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
}
.contact-page .info .email {
  margin: 2.344vw 0;
  width: 100%;
}
@media (max-width: 1150px) {
  .contact-page .info .email {
    margin: 2.609vw 0;
  }
}
@media (max-width: 900px) {
  .contact-page .info .email {
    margin: 3.333vw 0;
  }
}
@media (max-width: 700px) {
  .contact-page .info .email {
    margin: 30px 0;
  }
}
.contact-page .info .email .hover {
  width: 100%;
}
.contact-page .info .email .hover p,
.contact-page .info .email .hover .fake {
  line-height: 0%;
}
.contact-page .info .email .hover .fake {
  transform: translateY(200%);
}
.contact-page .info .email .hover:hover .fake {
  transform: translateY(-100%);
}
.contact-page .info .email img {
  width: 53.594vw;
}
@media (max-width: 1150px) {
  .contact-page .info .email img {
    width: 52.174vw;
  }
}
@media (max-width: 900px) {
  .contact-page .info .email img {
    width: 61.778vw;
  }
}
@media (max-width: 700px) {
  .contact-page .info .email img {
    width: 435px;
  }
}
@media (max-width: 600px) {
  .contact-page .info .email img {
    width: 100%;
  }
}
.contact-page .location {
  overflow: hidden;
  margin-top: 0%;
  height: 30.469vw;
  position: relative;
}
@media (max-width: 1150px) {
  .contact-page .location {
    height: 33.913vw;
  }
}
@media (max-width: 900px) {
  .contact-page .location {
    height: 43.333vw;
  }
}
@media (max-width: 700px) {
  .contact-page .location {
    height: 390px;
  }
}
@media (max-width: 450px) {
  .contact-page .location {
    height: 473px;
  }
}
.contact-page .location img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 700px) {
  .contact-page .location img {
    height: 100%;
  }
}
.contact-page .location .info-details {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.594vw;
}
@media (max-width: 1150px) {
  .contact-page .location .info-details {
    padding: 4vw;
  }
}
@media (max-width: 900px) {
  .contact-page .location .info-details {
    padding: 4.667vw;
  }
}
@media (max-width: 700px) {
  .contact-page .location .info-details {
    padding: 42px;
  }
}
@media (max-width: 450px) {
  .contact-page .location .info-details {
    padding: 33px;
  }
}
.contact-page .location .info-details .icon-circle {
  background-color: #fff;
  border-radius: 50%;
  height: 3.906vw;
  width: 3.906vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1150px) {
  .contact-page .location .info-details .icon-circle {
    height: 4.348vw;
    width: 4.348vw;
  }
}
@media (max-width: 900px) {
  .contact-page .location .info-details .icon-circle {
    height: 4.889vw;
    width: 4.889vw;
  }
}
@media (max-width: 700px) {
  .contact-page .location .info-details .icon-circle {
    height: 44px;
    width: 44px;
  }
}
.contact-page .location .info-details .icon-circle .icon {
  height: -moz-max-content;
  height: max-content;
  width: 40%;
}
.contact-page .location .info-details h2 {
  margin-bottom: 1.719vw;
}
@media (max-width: 1150px) {
  .contact-page .location .info-details h2 {
    margin-bottom: 1.913vw;
  }
}
@media (max-width: 900px) {
  .contact-page .location .info-details h2 {
    margin-bottom: 2.444vw;
  }
}
@media (max-width: 700px) {
  .contact-page .location .info-details h2 {
    margin-bottom: 22px;
  }
}
@media (max-width: 450px) {
  .contact-page .location .info-details h2 {
    margin-bottom: 16px;
    font-size: 40px !important;
  }
}