.image-section {
  height: 100vh;
  overflow: hidden;
  position: relative;
  transition-delay: 666ms !important;
}
@media (max-width: 700px) {
  .image-section {
    height: 374px;
  }
}
.image-section img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 700px) {
  .image-section img {
    height: 100%;
  }
}