.description-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5.859vw;
  grid-row-gap: 1.016vw;
  grid-template-areas: ". ." "circles .";
}
@media (max-width: 1150px) {
  .description-section {
    grid-column-gap: 6.522vw;
    grid-row-gap: 1.13vw;
  }
}
@media (max-width: 900px) {
  .description-section {
    grid-column-gap: 5.556vw;
    grid-row-gap: 1.111vw;
  }
}
@media (max-width: 700px) {
  .description-section {
    grid-template-columns: 1fr;
    grid-template-areas: "." "." "." "circles";
    grid-row-gap: 10px;
  }
  .description-section h2 {
    margin-bottom: 10px;
  }
}
.description-section .text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.016vw;
}
@media (max-width: 1150px) {
  .description-section .text-wrapper {
    gap: 1.13vw;
  }
}
@media (max-width: 900px) {
  .description-section .text-wrapper {
    gap: 1.111vw;
  }
}
@media (max-width: 700px) {
  .description-section .text-wrapper {
    gap: 10px;
  }
}
.description-section .bottom {
  align-self: flex-end;
  grid-area: circles;
}
@media (max-width: 700px) {
  .description-section .bottom {
    margin-top: 25px;
    display: flex;
    align-items: center;
  }
}
.description-section .bottom .circles {
  display: flex;
}
.description-section .bottom .circles .circle {
  background-color: #313131;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.282vw;
  width: 3.282vw;
  border: 0.313vw solid #000;
  margin-left: -0.939vw;
  padding: 0.781vw;
}
@media (max-width: 1150px) {
  .description-section .bottom .circles .circle {
    height: 3.174vw;
    width: 3.174vw;
    border: 0.304vw solid #000;
    margin-left: -0.912vw;
    padding: 0.783vw;
  }
}
@media (max-width: 900px) {
  .description-section .bottom .circles .circle {
    height: 4.056vw;
    width: 4.056vw;
    border: 0.389vw solid #000;
    margin-left: -1.167vw;
    padding: 1.111vw;
  }
}
@media (max-width: 700px) {
  .description-section .bottom .circles .circle {
    height: 46.5px;
    width: 46.5px;
    border: 3.5px solid #000;
    margin-left: -10.5px;
    padding: 12px;
  }
}
.description-section .bottom .circles .circle:first-child {
  margin-left: -0.313vw;
}
@media (max-width: 1150px) {
  .description-section .bottom .circles .circle:first-child {
    margin-left: -0.304vw;
  }
}
@media (max-width: 900px) {
  .description-section .bottom .circles .circle:first-child {
    margin-left: -0.389vw;
  }
}
@media (max-width: 700px) {
  .description-section .bottom .circles .circle:first-child {
    margin-left: -3.5px;
  }
}
.description-section .bottom .circles .circle svg {
  width: 100%;
}
.description-section .bottom .circles .plus {
  background-color: #fff;
}
.description-section .bottom .circles .plus svg {
  fill: #000;
  width: 52%;
}
.description-section .bottom .desc {
  margin-top: 1.094vw;
}
@media (max-width: 1150px) {
  .description-section .bottom .desc {
    margin-top: 1.043vw;
  }
}
@media (max-width: 900px) {
  .description-section .bottom .desc {
    margin-top: 1.333vw;
  }
}
@media (max-width: 700px) {
  .description-section .bottom .desc {
    margin-top: 0;
    margin-left: 20px;
  }
}
.description-section .bottom .desc span {
  opacity: 0.5;
}
.description-section .bottom .desc span:first-child {
  opacity: 1;
}
.description-section .description div:nth-child(1) {
  transition-delay: 262ms;
}
.description-section .description div:nth-child(2) {
  transition-delay: 302ms;
}
.description-section .description div:nth-child(3) {
  transition-delay: 342ms;
}
.description-section .description div:nth-child(4) {
  transition-delay: 382ms;
}
.description-section .description div:nth-child(5) {
  transition-delay: 422ms;
}
.description-section .description div:nth-child(6) {
  transition-delay: 462ms;
}
.description-section .description div:nth-child(7) {
  transition-delay: 502ms;
}
.description-section .description div:nth-child(8) {
  transition-delay: 542ms;
}
.description-section .description div:nth-child(9) {
  transition-delay: 582ms;
}
.description-section .description div:nth-child(10) {
  transition-delay: 622ms;
}
.description-section .description div:nth-child(11) {
  transition-delay: 662ms;
}
.description-section .description div:nth-child(12) {
  transition-delay: 702ms;
}
.description-section .description div:nth-child(13) {
  transition-delay: 742ms;
}
.description-section .description div:nth-child(14) {
  transition-delay: 782ms;
}
.description-section .description div:nth-child(15) {
  transition-delay: 822ms;
}
.description-section .description div:nth-child(16) {
  transition-delay: 862ms;
}
.description-section .description div:nth-child(17) {
  transition-delay: 902ms;
}
.description-section .description div:nth-child(18) {
  transition-delay: 942ms;
}
.description-section .description div:nth-child(19) {
  transition-delay: 982ms;
}
.description-section .description div:nth-child(20) {
  transition-delay: 1022ms;
}
.description-section .description div:nth-child(21) {
  transition-delay: 1062ms;
}
.description-section .description div:nth-child(22) {
  transition-delay: 1102ms;
}
.description-section .description div:nth-child(23) {
  transition-delay: 1142ms;
}
.description-section .description div:nth-child(24) {
  transition-delay: 1182ms;
}
.description-section .description div:nth-child(25) {
  transition-delay: 1222ms;
}