.home-page .hero {
  min-height: 38vw !important;
}
.home-page .hero h1 {
  margin-bottom: 3.125vw;
}
@media (max-width: 1150px) {
  .home-page .hero h1 {
    margin-bottom: 3.217vw;
  }
}
@media (max-width: 900px) {
  .home-page .hero h1 {
    margin-bottom: 4.556vw;
  }
}
@media (max-width: 700px) {
  .home-page .hero h1 {
    margin-bottom: 30px;
  }
}
@media (max-width: 450px) {
  .home-page .hero h1 {
    margin-bottom: 24px;
    font-size: 11.567vw !important;
  }
}
.home-page .hero .buttons {
  display: flex;
  justify-content: space-between;
}
.home-page .hero .buttons .cta-buttons {
  display: flex;
  gap: 2.422vw;
}
@media (max-width: 1150px) {
  .home-page .hero .buttons .cta-buttons {
    gap: 2.696vw;
  }
}
@media (max-width: 900px) {
  .home-page .hero .buttons .cta-buttons {
    gap: 26px;
  }
}
@media (max-width: 700px) {
  .home-page .hero .buttons .cta-buttons {
    gap: 23px;
  }
}
@media (max-width: 450px) {
  .home-page .hero .buttons .cta-buttons {
    gap: 21px;
  }
}
@media (max-width: 370px) {
  .home-page .hero .buttons .cta-buttons .button-dark {
    display: none;
  }
}
.home-page .hero .buttons.show-element {
  transition-delay: 620ms;
}
.home-page .section-wrapper {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  overflow: hidden;
}
@media (max-width: 900px) {
  .home-page .section-wrapper {
    position: relative;
    width: 100% !important;
    margin: 0;
  }
  .home-page .section-wrapper .movable-button {
    transform: scale(1) !important;
    display: flex !important;
    position: absolute;
    opacity: 0;
    z-index: 10000000000;
    transform: translateY(50px);
    animation: show 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms forwards;
  }
}
@keyframes show {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.home-page .show-reel {
  height: 45.625vw;
}
.home-page .show-reel .wrapper {
  transition-delay: 620ms !important;
}
@media (max-width: 1150px) {
  .home-page .show-reel {
    height: 47.043vw;
  }
}
@media (max-width: 900px) {
  .home-page .show-reel {
    height: 52.222vw;
  }
}
@media (max-width: 700px) {
  .home-page .show-reel {
    height: 418px;
    margin: 0;
  }
}
@media (max-width: 600px) {
  .home-page .show-reel {
    height: 358px;
  }
}
.home-page .show-reel video {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 700px) {
  .home-page .show-reel video {
    height: 418px;
  }
}
@media (max-width: 600px) {
  .home-page .show-reel video {
    height: 358px;
  }
}
.home-page .modal {
  z-index: 10000000000000000000;
  background-color: rgba(0, 0, 0, 0.94);
  position: fixed;
  padding: 8.203vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 1150px) {
  .home-page .modal {
    padding: 9.13vw;
  }
}
@media (max-width: 900px) {
  .home-page .modal {
    padding: 16.111vw;
  }
}
@media (max-width: 700px) {
  .home-page .modal {
    padding: 0;
  }
}
.home-page .modal iframe {
  width: 100%;
  height: 100%;
}
.home-page .modal .button .hamburger-bar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 4.688vw;
  height: 50px;
  z-index: 999999;
  margin-top: 3.516vw;
}
@media (max-width: 1150px) {
  .home-page .modal .button .hamburger-bar {
    right: 5.217vw;
    margin-top: 4vw;
  }
}
@media (max-width: 900px) {
  .home-page .modal .button .hamburger-bar {
    margin-top: 5vw;
    right: 4.444vw;
  }
}
@media (max-width: 700px) {
  .home-page .modal .button .hamburger-bar {
    margin-top: 37px;
    right: 40px;
  }
}
@media (max-width: 600px) {
  .home-page .modal .button .hamburger-bar {
    margin-top: 30px;
  }
}
@media (max-width: 450px) {
  .home-page .modal .button .hamburger-bar {
    right: 30px;
  }
}
.home-page .modal .button .hamburger-bar .line {
  background-color: #fff;
  width: 3.125vw;
  height: 1px;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1) 83ms;
  position: relative;
  margin-left: auto;
}
@media (max-width: 1150px) {
  .home-page .modal .button .hamburger-bar .line {
    width: 3.478vw;
  }
}
@media (max-width: 900px) {
  .home-page .modal .button .hamburger-bar .line {
    width: 3.889vw;
  }
}
@media (max-width: 700px) {
  .home-page .modal .button .hamburger-bar .line {
    width: 30px;
  }
}
.home-page .modal .button .hamburger-bar .line:first-child {
  top: 0.508vw;
}
@media (max-width: 1150px) {
  .home-page .modal .button .hamburger-bar .line:first-child {
    top: 0.565vw;
  }
}
@media (max-width: 900px) {
  .home-page .modal .button .hamburger-bar .line:first-child {
    top: 0.6665vw;
  }
}
@media (max-width: 700px) {
  .home-page .modal .button .hamburger-bar .line:first-child {
    top: 4.5px;
  }
}
.home-page .modal .button .hamburger-bar .line:last-child {
  bottom: 0.508vw;
}
@media (max-width: 1150px) {
  .home-page .modal .button .hamburger-bar .line:last-child {
    bottom: 0.565vw;
  }
}
@media (max-width: 900px) {
  .home-page .modal .button .hamburger-bar .line:last-child {
    bottom: 0.6665vw;
  }
}
@media (max-width: 700px) {
  .home-page .modal .button .hamburger-bar .line:last-child {
    bottom: 4.5px;
  }
}
.home-page .modal .active-button .hamburger-bar .line {
  background-color: #fff !important;
}
.home-page .modal .active-button .hamburger-bar .line:first-child {
  transform: rotate(-45deg);
  transform-origin: center;
  top: 0;
}
.home-page .modal .active-button .hamburger-bar .line:last-child {
  transform: rotate(45deg);
  transform-origin: center;
  position: relative;
  bottom: 0;
}
.home-page .show-reel.animate-element {
  transform: translate3d(0, 0, 0) !important;
  position: relative;
  top: 50px;
  transition: 670ms top cubic-bezier(0.165, 0.84, 0.44, 1), 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  transition-delay: 666ms !important;
}
.home-page .show-reel.show-element {
  top: 0px;
}
.home-page .about-us {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 5.859vw;
       column-gap: 5.859vw;
  row-gap: 4.688vw;
}
@media (max-width: 1150px) {
  .home-page .about-us {
    -moz-column-gap: 6.522vw;
         column-gap: 6.522vw;
    row-gap: 5.217vw;
  }
}
@media (max-width: 900px) {
  .home-page .about-us {
    -moz-column-gap: 5.556vw;
         column-gap: 5.556vw;
    row-gap: 5.556vw;
  }
}
@media (max-width: 700px) {
  .home-page .about-us {
    grid-template-columns: max-content 1fr;
    grid-template-areas: "title title" "desc desc" "button clutch";
    row-gap: 20px;
    -moz-column-gap: 25px;
         column-gap: 25px;
  }
  .home-page .about-us h2 {
    grid-area: title;
  }
  .home-page .about-us .description {
    grid-area: desc;
    margin-bottom: 15px;
  }
  .home-page .about-us .clutch-box {
    grid-area: clutch;
  }
}
.home-page .about-us h2 div:nth-child(1) {
  transition-delay: 262ms;
}
.home-page .about-us h2 div:nth-child(2) {
  transition-delay: 302ms;
}
.home-page .about-us h2 div:nth-child(3) {
  transition-delay: 342ms;
}
.home-page .about-us h2 div:nth-child(4) {
  transition-delay: 382ms;
}
.home-page .about-us .description p:first-child {
  margin-bottom: 1.016vw;
}
@media (max-width: 1150px) {
  .home-page .about-us .description p:first-child {
    margin-bottom: 1.13vw;
  }
}
@media (max-width: 900px) {
  .home-page .about-us .description p:first-child {
    margin-bottom: 1.111vw;
  }
}
@media (max-width: 700px) {
  .home-page .about-us .description p:first-child {
    margin-bottom: 10px;
  }
}
.home-page .about-us .description p:first-child div:nth-child(1) {
  transition-delay: 262ms;
}
.home-page .about-us .description p:first-child div:nth-child(2) {
  transition-delay: 302ms;
}
.home-page .about-us .description p:first-child div:nth-child(3) {
  transition-delay: 342ms;
}
.home-page .about-us .description p:first-child div:nth-child(4) {
  transition-delay: 382ms;
}
.home-page .about-us .description p:first-child div:nth-child(5) {
  transition-delay: 422ms;
}
.home-page .about-us .description p:first-child div:nth-child(6) {
  transition-delay: 462ms;
}
.home-page .about-us .description p:first-child div:nth-child(7) {
  transition-delay: 502ms;
}
.home-page .about-us .description p:first-child div:nth-child(8) {
  transition-delay: 542ms;
}
.home-page .about-us .description p:first-child div:nth-child(9) {
  transition-delay: 582ms;
}
.home-page .about-us .description p:first-child div:nth-child(10) {
  transition-delay: 622ms;
}
.home-page .about-us .description p:first-child div:nth-child(11) {
  transition-delay: 662ms;
}
.home-page .about-us .description p:first-child div:nth-child(12) {
  transition-delay: 702ms;
}
.home-page .about-us .description p:first-child div:nth-child(13) {
  transition-delay: 742ms;
}
.home-page .about-us .description p:first-child div:nth-child(14) {
  transition-delay: 782ms;
}
.home-page .about-us .description p:first-child div:nth-child(15) {
  transition-delay: 822ms;
}
.home-page .about-us .description p:first-child div:nth-child(16) {
  transition-delay: 862ms;
}
.home-page .about-us .description p:first-child div:nth-child(17) {
  transition-delay: 902ms;
}
.home-page .about-us .description p:first-child div:nth-child(18) {
  transition-delay: 942ms;
}
.home-page .about-us .description p:first-child div:nth-child(19) {
  transition-delay: 982ms;
}
.home-page .about-us .description p:first-child div:nth-child(20) {
  transition-delay: 1022ms;
}
.home-page .about-us .description p:first-child div:nth-child(21) {
  transition-delay: 1062ms;
}
.home-page .about-us .description p:first-child div:nth-child(22) {
  transition-delay: 1102ms;
}
.home-page .about-us .description p:first-child div:nth-child(23) {
  transition-delay: 1142ms;
}
.home-page .about-us .description p:first-child div:nth-child(24) {
  transition-delay: 1182ms;
}
.home-page .about-us .description p:first-child div:nth-child(25) {
  transition-delay: 1222ms;
}
.home-page .about-us .description p:last-child div:nth-child(1) {
  transition-delay: 542ms;
}
.home-page .about-us .description p:last-child div:nth-child(2) {
  transition-delay: 582ms;
}
.home-page .about-us .description p:last-child div:nth-child(3) {
  transition-delay: 622ms;
}
.home-page .about-us .description p:last-child div:nth-child(4) {
  transition-delay: 662ms;
}
.home-page .about-us .description p:last-child div:nth-child(5) {
  transition-delay: 702ms;
}
.home-page .about-us .description p:last-child div:nth-child(6) {
  transition-delay: 742ms;
}
.home-page .about-us .description p:last-child div:nth-child(7) {
  transition-delay: 782ms;
}
.home-page .about-us .description p:last-child div:nth-child(8) {
  transition-delay: 822ms;
}
.home-page .about-us .description p:last-child div:nth-child(9) {
  transition-delay: 862ms;
}
.home-page .about-us .description p:last-child div:nth-child(10) {
  transition-delay: 902ms;
}
.home-page .about-us .description p:last-child div:nth-child(11) {
  transition-delay: 942ms;
}
.home-page .about-us .description p:last-child div:nth-child(12) {
  transition-delay: 982ms;
}
.home-page .about-us .description p:last-child div:nth-child(13) {
  transition-delay: 1022ms;
}
.home-page .about-us .description p:last-child div:nth-child(14) {
  transition-delay: 1062ms;
}
.home-page .about-us .description p:last-child div:nth-child(15) {
  transition-delay: 1102ms;
}
.home-page .about-us .description p:last-child div:nth-child(16) {
  transition-delay: 1142ms;
}
.home-page .about-us .description p:last-child div:nth-child(17) {
  transition-delay: 1182ms;
}
.home-page .about-us .description p:last-child div:nth-child(18) {
  transition-delay: 1222ms;
}
.home-page .about-us .description p:last-child div:nth-child(19) {
  transition-delay: 1262ms;
}
.home-page .about-us .description p:last-child div:nth-child(20) {
  transition-delay: 1302ms;
}
.home-page .about-us .description p:last-child div:nth-child(21) {
  transition-delay: 1342ms;
}
.home-page .about-us .description p:last-child div:nth-child(22) {
  transition-delay: 1382ms;
}
.home-page .about-us .description p:last-child div:nth-child(23) {
  transition-delay: 1422ms;
}
.home-page .about-us .description p:last-child div:nth-child(24) {
  transition-delay: 1462ms;
}
.home-page .about-us .description p:last-child div:nth-child(25) {
  transition-delay: 1502ms;
}
.home-page .about-us .clutch-box {
  display: flex;
  gap: 3.125vw;
  align-items: flex-end;
}
@media (max-width: 1150px) {
  .home-page .about-us .clutch-box {
    gap: 3.391vw;
  }
}
@media (max-width: 900px) {
  .home-page .about-us .clutch-box {
    gap: 3.667vw;
  }
}
@media (max-width: 700px) {
  .home-page .about-us .clutch-box {
    align-items: center;
  }
}
@media (max-width: 700px) {
  .home-page .about-us .clutch-box .clutch-wrapper {
    display: none;
  }
}
.home-page .about-us .clutch-box .clutch {
  fill: white;
  height: -moz-fit-content;
  height: fit-content;
  width: 7.969vw;
}
@media (max-width: 1150px) {
  .home-page .about-us .clutch-box .clutch {
    width: 8.87vw;
  }
}
@media (max-width: 900px) {
  .home-page .about-us .clutch-box .clutch {
    width: 9.778vw;
  }
}
.home-page .about-us .clutch-box .stars {
  width: 6.641vw;
  margin-bottom: 0.781vw;
}
@media (max-width: 1150px) {
  .home-page .about-us .clutch-box .stars {
    width: 7.391vw;
    margin-bottom: 0.939vw;
  }
}
@media (max-width: 900px) {
  .home-page .about-us .clutch-box .stars {
    width: 8.111vw;
    margin-bottom: 1.111vw;
  }
}
@media (max-width: 700px) {
  .home-page .about-us .clutch-box .stars {
    width: 73px;
    margin-bottom: 8px;
  }
}
.home-page .about-us .clutch-box p {
  color: rgba(255, 255, 255, 0.7);
}
.home-page .cta-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 44.297vw;
  min-height: 567px;
  text-align: center;
  padding: 9.453vw 0;
}
@media (max-width: 1150px) {
  .home-page .cta-area {
    height: 49.304vw;
    padding: 10.522vw 0;
  }
}
@media (max-width: 900px) {
  .home-page .cta-area {
    height: 54.778vw;
    padding: 11.667vw 0;
  }
}
@media (max-width: 700px) {
  .home-page .cta-area {
    height: 438px;
    padding: 90px 0;
  }
}
@media (max-width: 450px) {
  .home-page .cta-area {
    height: 578px;
    padding: 104px;
  }
}
.home-page .cta-area .subtitle {
  margin-bottom: 1.406vw;
}
@media (max-width: 1150px) {
  .home-page .cta-area .subtitle {
    margin-bottom: 1.565vw;
  }
}
@media (max-width: 900px) {
  .home-page .cta-area .subtitle {
    margin-bottom: 1.667vw;
  }
}
@media (max-width: 700px) {
  .home-page .cta-area .subtitle {
    margin-bottom: 11px;
  }
}
@media (max-width: 450px) {
  .home-page .cta-area .subtitle {
    margin-bottom: 20px;
  }
}
.home-page .cta-area h2 {
  width: 60.781vw;
  text-align: center;
}
@media (max-width: 1150px) {
  .home-page .cta-area h2 {
    width: 56vw;
  }
}
@media (max-width: 900px) {
  .home-page .cta-area h2 {
    width: 70.778vw;
  }
}
@media (max-width: 700px) {
  .home-page .cta-area h2 {
    width: 85vw;
  }
}
@media (max-width: 600px) {
  .home-page .cta-area h2 {
    width: 90vw;
  }
}
.home-page .cta-area h2 div:nth-child(1) {
  transition-delay: 262ms;
}
.home-page .cta-area h2 div:nth-child(2) {
  transition-delay: 302ms;
}
.home-page .cta-area h2 div:nth-child(3) {
  transition-delay: 342ms;
}
.home-page .cta-area h2 div:nth-child(4) {
  transition-delay: 382ms;
}
.home-page .cta-area h2 div:nth-child(5) {
  transition-delay: 422ms;
}
.home-page .awards {
  display: grid;
  -moz-column-gap: 10.391vw;
       column-gap: 10.391vw;
  row-gap: 8.984vw;
  grid-template-columns: 1fr 1.64fr;
  opacity: 1;
  transition: opacity 300ms ease 300ms;
}
@media (max-width: 1150px) {
  .home-page .awards {
    -moz-column-gap: 10.609vw;
         column-gap: 10.609vw;
    row-gap: 8.696vw;
  }
}
@media (max-width: 900px) {
  .home-page .awards {
    -moz-column-gap: 10vw;
         column-gap: 10vw;
    row-gap: 5.889vw;
  }
}
@media (max-width: 700px) {
  .home-page .awards {
    -moz-column-gap: 80px;
         column-gap: 80px;
    row-gap: 68px;
    grid-template-columns: 0.4fr 1fr;
  }
}
@media (max-width: 600px) {
  .home-page .awards {
    grid-template-columns: 1fr;
    row-gap: 20px;
    grid-template-areas: "." "." "." "trophy";
  }
}
.home-page .awards .counter {
  font-size: 10.938vw;
  line-height: 100%;
  letter-spacing: -0.015rem;
  font-weight: 200;
}
@media (max-width: 1150px) {
  .home-page .awards .counter {
    font-size: 11.304vw;
  }
}
@media (max-width: 900px) {
  .home-page .awards .counter {
    font-size: 12.556vw;
  }
}
@media (max-width: 700px) {
  .home-page .awards .counter {
    font-size: 100px;
  }
}
.home-page .awards .trophy {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 600px) {
  .home-page .awards .trophy {
    grid-area: trophy;
    overflow: initial;
  }
}
@media (max-width: 450px) {
  .home-page .awards .trophy {
    grid-area: trophy;
    overflow: hidden;
    height: 130%;
    align-items: center;
    margin-top: 25px;
  }
}
.home-page .awards .trophy video {
  height: 100%;
  transform: rotate(-17deg) scale(2) translate(1.2%, 1.2%);
}
@media (max-width: 1150px) {
  .home-page .awards .trophy video {
    transform: rotate(-17deg) scale(1.75) translate(1.2%, 1.2%);
  }
}
@media (max-width: 600px) {
  .home-page .awards .trophy video {
    width: 100%;
    height: auto;
    transform: rotate(0deg) scale(1) translate(0%, 0%);
  }
}
@media (max-width: 450px) {
  .home-page .awards .trophy video {
    transform: rotate(0deg) scale(1.3) translate(0%, 0%);
  }
}
.home-page .awards .award-list ul {
  list-style-type: none;
}
@media (max-width: 600px) {
  .home-page .awards .award-list ul {
    padding-top: 30px;
  }
}
@media (max-width: 450px) {
  .home-page .awards .award-list ul {
    padding-top: 17px;
  }
}
.home-page .awards .award-list ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.125vw;
}
@media (max-width: 1150px) {
  .home-page .awards .award-list ul li {
    margin-bottom: 3.391vw;
  }
}
@media (max-width: 900px) {
  .home-page .awards .award-list ul li {
    margin-bottom: 3.667vw;
  }
}
@media (max-width: 700px) {
  .home-page .awards .award-list ul li {
    margin-bottom: 27px;
  }
}
@media (max-width: 450px) {
  .home-page .awards .award-list ul li {
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 22px;
  }
}
.home-page .awards .award-list ul li .category {
  display: flex;
  gap: 2.422vw;
  opacity: 0.5;
}
@media (max-width: 1150px) {
  .home-page .awards .award-list ul li .category {
    gap: 2.696vw;
  }
}
@media (max-width: 900px) {
  .home-page .awards .award-list ul li .category {
    gap: 3vw;
  }
}
@media (max-width: 700px) {
  .home-page .awards .award-list ul li .category {
    gap: 22px;
  }
}
@media (max-width: 450px) {
  .home-page .awards .award-list ul li .category {
    gap: 8px;
  }
}
.home-page .awards .award-list ul li:nth-child(1) {
  transition-delay: 262ms;
}
.home-page .awards .award-list ul li:nth-child(2) {
  transition-delay: 302ms;
}
.home-page .awards .award-list ul li:nth-child(3) {
  transition-delay: 342ms;
}
.home-page .awards .award-list ul li:nth-child(4) {
  transition-delay: 382ms;
}
.home-page .awards .award-list ul li:nth-child(5) {
  transition-delay: 422ms;
}
.home-page .awards .award-list ul li:nth-child(6) {
  transition-delay: 462ms;
}
.home-page .awards .award-list ul li:nth-child(7) {
  transition-delay: 502ms;
}
.home-page .awards .award-list ul li:nth-child(8) {
  transition-delay: 542ms;
}
.home-page .awards .award-list ul li:nth-child(9) {
  transition-delay: 582ms;
}
.home-page .awards .award-list ul li:nth-child(10) {
  transition-delay: 622ms;
}
.home-page .awards .award-list .more {
  margin-top: 11.719vw;
}
@media (max-width: 1150px) {
  .home-page .awards .award-list .more {
    margin-top: 8.696vw;
  }
}
@media (max-width: 900px) {
  .home-page .awards .award-list .more {
    margin-top: 8.667vw;
  }
}
@media (max-width: 700px) {
  .home-page .awards .award-list .more {
    margin-top: 67px;
  }
}
@media (max-width: 600px) {
  .home-page .awards .award-list .more {
    margin-top: 47px;
  }
}
@media (max-width: 450px) {
  .home-page .awards .award-list .more {
    margin-top: 37px;
  }
}
.home-page .awards.hide {
  opacity: 0;
  transition: opacity 300ms ease 0ms;
}
.home-page .project-media {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease 0ms, background-image 500ms ease;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}
.home-page .project-media.show,
.home-page .project-details.show {
  transition: opacity 300ms ease 300ms, background-image 150ms ease;
}
.home-page .project-details {
  bottom: 4.688vw;
  left: 4.688vw;
  right: 4.688vw;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  height: 4.688vw;
}
@media (max-width: 1150px) {
  .home-page .project-details {
    bottom: 5.217vw;
    left: 5.217vw;
    right: 5.217vw;
    height: 4.609vw;
  }
}
@media (max-width: 900px) {
  .home-page .project-details {
    bottom: 30px;
    left: 0;
    right: 0;
    height: -moz-max-content;
    height: max-content;
    pointer-events: initial;
    justify-content: center;
  }
}
@media (max-width: 900px) {
  .home-page .project-details .info {
    display: none;
  }
}
.home-page .project-details .info .case-name {
  opacity: 0.58;
  margin-top: 0.547vw;
}
@media (max-width: 1150px) {
  .home-page .project-details .info .case-name {
    margin-top: 0.609vw;
  }
}
.home-page .project-details .button-scroll {
  pointer-events: initial;
}
@media (max-width: 900px) {
  .home-page .project-details .button-scroll {
    display: none;
  }
}
.home-page .project-details .button-light {
  display: none;
}
@media (max-width: 900px) {
  .home-page .project-details .button-light {
    display: inherit;
    position: relative;
    z-index: 1000000000000;
  }
}
.home-page .learn-more-btn {
  font-size: 1.25vw;
  padding: 0.859vw 0.938vw;
  border-radius: 0.313vw;
  width: -moz-max-content;
  width: max-content;
  color: #000;
  position: fixed;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 100000000000000000;
}
@media (max-width: 1150px) {
  .home-page .learn-more-btn {
    font-size: 1.391vw;
    padding: 0.957vw 1.043vw;
    border-radius: 0.348vw;
  }
}
@media (max-width: 900px) {
  .home-page .learn-more-btn {
    display: none;
  }
}
.home-page .learn-more-btn.show {
  opacity: 1;
}
.home-page #projects-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-page .projects {
  text-align: center;
  padding-top: 25vh;
  padding-bottom: 25vh;
  opacity: 1;
  transition: opacity 0.3s ease;
  position: relative;
}
@media (max-width: 1150px) {
  .home-page .projects {
    padding-top: 50vh;
    padding-bottom: 50vh;
  }
}
.home-page .projects .wrapper {
  position: relative;
  z-index: 112;
  margin-bottom: 7.813vw;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: default;
}
@media (max-width: 1150px) {
  .home-page .projects .wrapper {
    margin-bottom: 8.435vw;
  }
}
@media (max-width: 900px) {
  .home-page .projects .wrapper {
    margin-bottom: 9.333vw;
  }
}
@media (max-width: 700px) {
  .home-page .projects .wrapper {
    margin-bottom: 75px;
  }
}
@media (max-width: 600px) {
  .home-page .projects .wrapper {
    margin-bottom: 125px;
  }
}
.home-page .projects .wrapper .title {
  letter-spacing: -0.015rem;
  font-size: 6.406vw;
  line-height: 132%;
  opacity: 0.2;
  transition: 750ms cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: default;
  font-weight: 500;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 1150px) {
  .home-page .projects .wrapper .title {
    font-size: 7.13vw;
  }
}
@media (max-width: 900px) {
  .home-page .projects .wrapper .title {
    font-size: 7.8vw;
  }
}
@media (max-width: 700px) {
  .home-page .projects .wrapper .title {
    font-size: 63px;
  }
}
@media (max-width: 600px) {
  .home-page .projects .wrapper .title {
    font-size: 58px;
  }
}
@media (max-width: 450px) {
  .home-page .projects .wrapper .title {
    font-size: 12.889vw;
  }
}
.home-page .projects .wrapper .active {
  opacity: 1;
  transform: scale(1.26834218);
}
.home-page .projects .wrapper .neutral {
  opacity: 1;
  transform: scale(1.13417109);
}
.home-page .projects.hide {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 900px) {
  .home-page .movable-button {
    display: none;
  }
}
.home-page .services {
  display: flex;
  flex-direction: column;
  gap: 3.906vw;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (max-width: 1150px) {
  .home-page .services {
    gap: 5.043vw;
  }
}
@media (max-width: 900px) {
  .home-page .services {
    gap: 6.111vw;
  }
}
@media (max-width: 700px) {
  .home-page .services {
    gap: 50px;
  }
}
.home-page .services .item {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 700px) {
  .home-page .services .item {
    flex-direction: column;
  }
}
.home-page .services .item .label {
  opacity: 0.5;
  font-size: 1.25vw;
  transition-delay: 263ms;
}
@media (max-width: 1150px) {
  .home-page .services .item .label {
    font-size: 1.391vw;
  }
}
@media (max-width: 900px) {
  .home-page .services .item .label {
    font-size: 14px;
  }
}
.home-page .services .item h2 {
  cursor: none;
  width: 57.969vw;
  font-weight: 300;
  letter-spacing: -0.015rem;
}
@media (max-width: 1150px) {
  .home-page .services .item h2 {
    width: 60.174vw;
  }
}
@media (max-width: 900px) {
  .home-page .services .item h2 {
    width: 62vw;
  }
}
@media (max-width: 700px) {
  .home-page .services .item h2 {
    width: initial;
    margin-top: 25px;
  }
}
@media (max-width: 450px) {
  .home-page .services .item h2 {
    font-size: 38px;
  }
}
.home-page .services .item h2 div {
  width: -moz-max-content;
  width: max-content;
}
.home-page .services .item h2 div:nth-child(1) {
  transition-delay: 262ms;
}
.home-page .services .item h2 div:nth-child(2) {
  transition-delay: 302ms;
}
.home-page .services .item h2 div:nth-child(3) {
  transition-delay: 342ms;
}
.home-page .services .item h2 div:nth-child(4) {
  transition-delay: 382ms;
}
.home-page .services .item h2 div:nth-child(5) {
  transition-delay: 422ms;
}
.home-page .services .item h2 div:nth-child(6) {
  transition-delay: 462ms;
}
.home-page .services .item h2 div:nth-child(7) {
  transition-delay: 502ms;
}
.home-page .services .item h2 div:nth-child(8) {
  transition-delay: 542ms;
}
.home-page .services .item h2 div:nth-child(9) {
  transition-delay: 582ms;
}
.home-page .services .item h2 div:nth-child(10) {
  transition-delay: 622ms;
}
.home-page .services.hide {
  opacity: 0;
}