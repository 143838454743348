.services-page .hero h1 {
  max-width: 60vw;
}
@media (max-width: 700px) {
  .services-page .hero h1 {
    max-width: 90%;
  }
}
@media (max-width: 600px) {
  .services-page .hero h1 {
    max-width: 95%;
  }
}
@media (max-width: 450px) {
  .services-page .hero h1 {
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .services-page .description-section .bottom {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
  .services-page .description-section .bottom .desc {
    margin-left: 0px;
    margin-top: 15px;
  }
}
.services-page .trophy-section {
  background-color: #fff;
  color: #000;
  height: 51.953vw;
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 1150px) {
  .services-page .trophy-section {
    height: 57.826vw;
  }
}
@media (max-width: 900px) {
  .services-page .trophy-section {
    height: 64.444vw;
  }
}
@media (max-width: 700px) {
  .services-page .trophy-section {
    height: 580px;
  }
}
.services-page .trophy-section .left {
  align-self: center;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services-page .trophy-section .left .subtitle {
  margin-bottom: 1.406vw;
}
@media (max-width: 1150px) {
  .services-page .trophy-section .left .subtitle {
    margin-bottom: 1.565vw;
  }
}
@media (max-width: 900px) {
  .services-page .trophy-section .left .subtitle {
    margin-bottom: 2.222vw;
  }
}
@media (max-width: 700px) {
  .services-page .trophy-section .left .subtitle {
    margin-bottom: 2.222vw;
  }
}
@media (max-width: 600px) {
  .services-page .trophy-section .left .subtitle {
    margin-bottom: 20px;
  }
}
.services-page .trophy-section .image {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 37.813vw;
  z-index: 1;
}
@media (max-width: 1150px) {
  .services-page .trophy-section .image {
    width: 34.87vw;
  }
}
@media (max-width: 900px) {
  .services-page .trophy-section .image {
    width: 41.889vw;
  }
}
@media (max-width: 700px) {
  .services-page .trophy-section .image {
    width: 278px;
  }
}
@media (max-width: 600px) {
  .services-page .trophy-section .image {
    display: none;
  }
}
.services-page .trophy-section .image img {
  width: 51.328vw;
}
@media (max-width: 1150px) {
  .services-page .trophy-section .image img {
    width: 57.13vw;
    transform: rotate(-7deg);
  }
}
@media (max-width: 900px) {
  .services-page .trophy-section .image img {
    width: 73vw;
  }
}
@media (max-width: 700px) {
  .services-page .trophy-section .image img {
    width: 657px;
  }
}
@media (max-width: 600px) {
  .services-page .trophy-section .image img {
    width: 457px;
  }
}

.render-services .services-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5.859vw;
}
@media (max-width: 1150px) {
  .render-services .services-item {
    grid-gap: 6.522vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item {
    grid-gap: 5.556vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item {
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }
}
@media (max-width: 450px) {
  .render-services .services-item {
    grid-gap: 55px;
  }
}
.render-services .services-item .info {
  padding: 2.734vw 0;
  height: 39.922vw;
  margin-bottom: 1.563vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1150px) {
  .render-services .services-item .info {
    height: 44.435vw;
    padding: 3.043vw 0;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .info {
    height: 100%;
    padding: 0;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .info {
    padding: 0;
  }
}
.render-services .services-item .info h2 {
  margin-bottom: 1.25vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .info h2 {
    margin-bottom: 1.391vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .info h2 {
    margin-bottom: 1.778vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .info h2 {
    margin-bottom: 16px;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .info .technology {
    margin-top: 51px;
  }
}
@media (max-width: 450px) {
  .render-services .services-item .info .technology {
    margin-top: 38px;
  }
}
.render-services .services-item .info .technology .title {
  opacity: 0.5;
  margin-bottom: 1.016vw;
  font-size: 1.25vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .info .technology .title {
    font-size: 1.391vw;
    margin-bottom: 1.043vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .info .technology .title {
    font-size: 1.778vw;
    margin-bottom: 1.444vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .info .technology .title {
    font-size: 16px;
    margin-bottom: 13px;
  }
}
.render-services .services-item .slider {
  grid-area: image;
}
@media (max-width: 900px) {
  .render-services .services-item .slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.render-services .services-item .slider .images {
  position: relative;
  height: 39.922vw;
  margin-bottom: 1.563vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .slider .images {
    height: 44.435vw;
    margin-bottom: 1.739vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .slider .images {
    height: 46.667vw;
    margin-bottom: 2.444vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .slider .images {
    height: 420px;
    margin-bottom: 22px;
  }
}
.render-services .services-item .slider .images img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
@media (max-width: 700px) {
  .render-services .services-item .slider .images img {
    height: 100%;
  }
}
.render-services .services-item .slider .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.render-services .services-item .slider .controls .case {
  font-size: 1.25vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .slider .controls .case {
    font-size: 1.391vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .slider .controls .case {
    font-size: 1.778vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .slider .controls .case {
    font-size: 16px;
  }
}
.render-services .services-item .slider .controls .case .opacity {
  opacity: 0.5;
  margin-right: 1.328vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .slider .controls .case .opacity {
    margin-right: 1.391vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .slider .controls .case .opacity {
    margin-right: 1.778vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .slider .controls .case .opacity {
    margin-right: 16px;
  }
}
.render-services .services-item .slider .controls .arrows {
  display: flex;
  gap: 0.781vw;
}
@media (max-width: 1150px) {
  .render-services .services-item .slider .controls .arrows {
    gap: 0.87vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .slider .controls .arrows {
    gap: 1.111vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .slider .controls .arrows {
    gap: 10px;
  }
}
.render-services .services-item .slider .controls .arrows .arrow {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  width: 1.719vw;
  height: 1.719vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 1150px) {
  .render-services .services-item .slider .controls .arrows .arrow {
    width: 1.913vw;
    height: 1.913vw;
  }
}
@media (max-width: 900px) {
  .render-services .services-item .slider .controls .arrows .arrow {
    width: 2.444vw;
    height: 2.444vw;
  }
}
@media (max-width: 700px) {
  .render-services .services-item .slider .controls .arrows .arrow {
    width: 22px;
    height: 22px;
  }
}
.render-services .services-item .slider .controls .arrows .arrow svg {
  width: 45%;
}
.render-services .services-item .slider .controls .arrows .left {
  transform: rotate(180deg);
}
.render-services .services-item:nth-child(even) {
  grid-template-areas: "image .";
}
@media (max-width: 700px) {
  .render-services .services-item:nth-child(even) {
    grid-template-areas: "." "image";
  }
}
.render-services .services-item:nth-child(odd) {
  grid-template-areas: ". image";
}
@media (max-width: 700px) {
  .render-services .services-item:nth-child(odd) {
    grid-template-areas: "." "image";
  }
}