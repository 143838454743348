.video-intro {
  position: fixed;
  right: 4.878vw;
  bottom: 3.841vw;
  z-index: 100000;
  transition: 0.5s ease-in-out;
  transform: translateY(50px);
  opacity: 0;
}
.video-intro .video-wrapper {
  position: relative;
  height: 14.756vw;
  transform-origin: right bottom;
  transition: 0.3s ease-in-out;
}
.video-intro .video-wrapper video {
  border-radius: 0.976vw;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.video-intro .video-wrapper .close {
  width: 2.073vw;
  position: absolute;
  right: -0.61vw;
  top: -0.61vw;
  z-index: 1000000;
  cursor: pointer;
}
.video-intro .video-wrapper .expand {
  width: 1.707vw;
  position: absolute;
  bottom: 0.488vw;
  right: 0.488vw;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.video-intro .open {
  transform: scale(1.79);
}
@media (max-width: 1440px) {
  .video-intro {
    right: 4.861vw;
    bottom: 4.653vw;
  }
  .video-intro .video-wrapper {
    height: 16.806vw;
  }
  .video-intro .video-wrapper video {
    border-radius: 1.111vw;
  }
  .video-intro .video-wrapper .close {
    width: 2.361vw;
    right: -0.694vw;
    top: -0.694vw;
  }
  .video-intro .video-wrapper .expand {
    width: 1.944vw;
    bottom: 0.556vw;
    right: 0.556vw;
  }
}
@media (max-width: 1280px) {
  .video-intro {
    right: 6.25vw;
    bottom: 5.469vw;
  }
  .video-intro .video-wrapper {
    height: 16.25vw;
  }
  .video-intro .video-wrapper video {
    border-radius: 1.25vw;
  }
  .video-intro .video-wrapper .close {
    width: 2.656vw;
    right: -0.781vw;
    top: -0.781vw;
  }
  .video-intro .video-wrapper .expand {
    width: 2.188vw;
    bottom: 0.625vw;
    right: 0.625vw;
  }
}
@media (max-width: 1150px) {
  .video-intro {
    right: 5.217vw;
    bottom: 2.87vw;
  }
  .video-intro .video-wrapper {
    height: 18.087vw;
  }
  .video-intro .video-wrapper video {
    border-radius: 1.391vw;
  }
  .video-intro .video-wrapper .close {
    width: 2.957vw;
    right: -0.87vw;
    top: -0.87vw;
  }
  .video-intro .video-wrapper .expand {
    width: 2.435vw;
    bottom: 0.696vw;
    right: 0.696vw;
  }
}
@media (max-width: 900px) {
  .video-intro {
    right: 54px;
    bottom: 36px;
  }
  .video-intro .video-wrapper {
    height: 177px;
  }
  .video-intro .video-wrapper video {
    border-radius: 16px;
  }
  .video-intro .video-wrapper .close {
    width: 34px;
    right: -10px;
    top: -10px;
  }
  .video-intro .video-wrapper .expand {
    width: 24px;
    bottom: 8px;
    right: 8px;
  }
}
@media (max-width: 600px) {
  .video-intro {
    right: 40px;
    bottom: 40px;
  }
  .video-intro .video-wrapper {
    height: 177px;
  }
}
@media (max-width: 375px) {
  .video-intro {
    right: 30px;
    bottom: 30px;
  }
}

.show-video {
  transform: translate(0);
  opacity: 1;
}