.render-blogs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0px !important;
  grid-gap: 5.859vw;
  min-height: 100vh;
}
@media (max-width: 1150px) {
  .render-blogs {
    grid-gap: 6.522vw;
  }
}
@media (max-width: 900px) {
  .render-blogs {
    grid-gap: 5.556vw;
  }
}
@media (max-width: 700px) {
  .render-blogs {
    grid-gap: 40px;
  }
}
@media (max-width: 600px) {
  .render-blogs {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
@media (max-width: 450px) {
  .render-blogs {
    grid-gap: 30px;
  }
}
.render-blogs .item {
  opacity: 0;
  transform: translateY(200px);
  animation: animationFade 1000ms cubic-bezier(0.19, 1, 0.22, 1) 233ms forwards;
}
@keyframes animationFade {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.render-blogs .load-init {
  transition-delay: 666ms;
}
.render-blogs .image-animate {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  height: 19.297vw;
}
@media (max-width: 1150px) {
  .render-blogs .image-animate {
    height: 21.478vw;
  }
}
@media (max-width: 900px) {
  .render-blogs .image-animate {
    height: 27.444vw;
  }
}
@media (max-width: 700px) {
  .render-blogs .image-animate {
    height: 247px;
  }
}
.render-blogs .image-animate .image-animate {
  height: 100%;
  transition-delay: 620ms !important;
}
.render-blogs .image-animate .wrapper {
  height: 100%;
  position: relative;
  transition: transform 0.1s ease, opacity 0.25s ease;
}
.render-blogs .image-animate .wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s ease;
}
.render-blogs .image-animate .wrapper:hover img {
  transform: scale(1.1);
}
.render-blogs .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1150px) {
  .render-blogs .info {
    top: 3.913vw;
    left: 3.913vw;
    right: 3.913vw;
    bottom: 3.913vw;
  }
}
@media (max-width: 900px) {
  .render-blogs .info {
    top: 5vw;
    left: 5vw;
    right: 5vw;
    bottom: 5vw;
  }
}
@media (max-width: 700px) {
  .render-blogs .info {
    top: 32px;
    left: 32px;
    right: 32px;
    bottom: 32px;
  }
}
.render-blogs .info .category {
  font-size: 1.25vw;
  opacity: 0.55;
  margin-top: 1.094vw;
}
@media (max-width: 1150px) {
  .render-blogs .info .category {
    font-size: 1.391vw;
    margin-top: 1.217vw;
  }
}
@media (max-width: 900px) {
  .render-blogs .info .category {
    font-size: 1.778vw;
    margin-top: 1.556vw;
  }
}
@media (max-width: 700px) {
  .render-blogs .info .category {
    font-size: 14px;
    margin-top: 10px;
  }
}
.render-blogs .info .title {
  font-size: 2.5vw;
  line-height: 132.9%;
  margin-top: 2.734vw;
}
@media (max-width: 1150px) {
  .render-blogs .info .title {
    font-size: 3.556vw;
  }
}
@media (max-width: 900px) {
  .render-blogs .info .title {
    font-size: 2.667vw;
  }
}
@media (max-width: 700px) {
  .render-blogs .info .title {
    font-size: 20px;
    margin-top: 20px;
  }
}