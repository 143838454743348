.movable-button {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  pointer-events: none;
  color: #000;
  z-index: 1000;
  transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1), left 50ms linear, top 50ms linear;
  font-size: 1.25vw;
}
@media (max-width: 1150px) {
  .movable-button {
    font-size: 1.391vw;
  }
}
@media (max-width: 900px) {
  .movable-button {
    transform: scale(1) !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 91px !important;
    height: 91px !important;
  }
}
@media (max-width: 700px) {
  .movable-button {
    width: 81px !important;
    height: 81px !important;
  }
}
@media (max-width: 600px) {
  .movable-button {
    width: 70px !important;
    height: 70px !important;
  }
}
@media (max-width: 450px) {
  .movable-button {
    width: 90px !important;
    height: 90px !important;
  }
}
.movable-button svg {
  width: 1.406vw;
}
@media (max-width: 1150px) {
  .movable-button svg {
    width: 1.565vw;
  }
}
@media (max-width: 900px) {
  .movable-button svg {
    width: 1.667vw;
  }
}
@media (max-width: 700px) {
  .movable-button svg {
    width: 13px;
  }
}
@media (max-width: 600px) {
  .movable-button svg {
    width: 12px;
  }
}
@media (max-width: 450px) {
  .movable-button svg {
    width: 15px;
  }
}