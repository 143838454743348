@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 1.406vw;
  line-height: 145.9%;
}
@media (max-width: 1150px) {
  body {
    font-size: 1.304vw;
  }
}
@media (max-width: 900px) {
  body {
    font-size: 16px;
  }
}
body ::-moz-selection {
  color: #000;
  background: #fff;
}
body ::selection {
  color: #000;
  background: #fff;
}

.container {
  margin-left: 4.688vw;
  margin-right: 4.688vw;
}
@media (max-width: 1150px) {
  .container {
    margin-left: 5.217vw;
    margin-right: 5.217vw;
  }
}
@media (max-width: 900px) {
  .container {
    margin-left: 4.444vw;
    margin-right: 4.444vw;
  }
}
@media (max-width: 700px) {
  .container {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (max-width: 450px) {
  .container {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.container-narrow {
  width: 76.563vw;
  margin: auto;
}
@media (max-width: 1150px) {
  .container-narrow {
    width: 80.87vw;
  }
}
@media (max-width: 900px) {
  .container-narrow {
    width: 82.222vw;
  }
}
@media (max-width: 700px) {
  .container-narrow {
    width: initial;
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (max-width: 450px) {
  .container-narrow {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.hover {
  overflow: hidden;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  cursor: pointer;
}
.hover .fake {
  position: absolute;
  transform: translateY(10%);
}
.hover p {
  transform: translateY(0%);
}
.hover p,
.hover .fake {
  transition: 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hover:hover p {
  transform: translateY(-105%);
}
.hover:hover .fake {
  transform: translateY(-100%);
}

.section-space {
  margin-top: 15.625vw;
  margin-bottom: 15.625vw;
}
@media (max-width: 1150px) {
  .section-space {
    margin-top: 17.391vw;
    margin-bottom: 17.391vw;
  }
}
@media (max-width: 900px) {
  .section-space {
    margin-top: 16.667vw;
    margin-bottom: 16.667vw;
  }
}
@media (max-width: 700px) {
  .section-space {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.white-section {
  background-color: #fff;
  color: #000;
}
.white-section .button-dark {
  background-color: #000;
  border: 1px solid #000;
}
.white-section .button-dark:hover {
  background-color: transparent;
}

svg {
  aspect-ratio: 1/1;
}

video {
  width: 100%;
}

h1 {
  font-size: 5.938vw;
  line-height: 104%;
  font-weight: 300;
  letter-spacing: -0.015rem;
}
@media (max-width: 1150px) {
  h1 {
    font-size: 6.2vw;
  }
}
@media (max-width: 900px) {
  h1 {
    font-size: 6.37vw;
  }
}
@media (max-width: 700px) {
  h1 {
    max-width: 520px;
  }
}
@media (max-width: 700px) {
  h1 {
    font-size: 48px;
  }
}
@media (max-width: 450px) {
  h1 {
    font-size: 46px;
  }
}
@media (max-width: 375px) {
  h1 {
    font-size: 46px !important;
  }
}

h2 {
  font-size: 4.688vw;
  font-weight: 300;
  line-height: 107%;
}
@media (max-width: 1150px) {
  h2 {
    font-size: 4.87vw;
  }
}
@media (max-width: 900px) {
  h2 {
    font-size: 5.111vw;
  }
}
@media (max-width: 700px) {
  h2 {
    font-size: 42px;
  }
}
@media (max-width: 450px) {
  h2 {
    font-size: 44px;
  }
}

.small-text {
  font-size: 0.781vw;
  line-height: 125%;
}
@media (max-width: 1150px) {
  .small-text {
    font-size: 0.87vw;
  }
}
@media (max-width: 900px) {
  .small-text {
    font-size: 0.967vw;
  }
}
@media (max-width: 700px) {
  .small-text {
    font-size: 10px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.separate-text {
  opacity: 0;
}

.fade-up {
  opacity: 0;
  top: 50px;
}

.fade-up-line div {
  opacity: 0 !important;
  top: 50px !important;
}

.fade-up.show-element {
  opacity: 1;
  position: relative;
  top: 0px;
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1),
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1);
}

.fade-up-line.show-element div {
  opacity: 1 !important;
  position: relative;
  top: 0px !important;
}
.fade-up-line.show-element div:nth-child(1) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 263ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 263ms;
}
.fade-up-line.show-element div:nth-child(2) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 303ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 303ms;
}
.fade-up-line.show-element div:nth-child(3) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 343ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 343ms;
}
.fade-up-line.show-element div:nth-child(4) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 383ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 383ms;
}
.fade-up-line.show-element div:nth-child(5) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 423ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 423ms;
}
.fade-up-line.show-element div:nth-child(6) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 463ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 463ms;
}
.fade-up-line.show-element div:nth-child(7) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 503ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 503ms;
}
.fade-up-line.show-element div:nth-child(8) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 543ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 543ms;
}
.fade-up-line.show-element div:nth-child(9) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 583ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 583ms;
}
.fade-up-line.show-element div:nth-child(10) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 623ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 623ms;
}
.fade-up-line.show-element div:nth-child(11) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 663ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 663ms;
}
.fade-up-line.show-element div:nth-child(12) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 703ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 703ms;
}
.fade-up-line.show-element div:nth-child(13) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 743ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 743ms;
}
.fade-up-line.show-element div:nth-child(14) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 783ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 783ms;
}
.fade-up-line.show-element div:nth-child(15) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 823ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 823ms;
}
.fade-up-line.show-element div:nth-child(16) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 863ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 863ms;
}
.fade-up-line.show-element div:nth-child(17) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 903ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 903ms;
}
.fade-up-line.show-element div:nth-child(18) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 943ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 943ms;
}
.fade-up-line.show-element div:nth-child(19) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 983ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 983ms;
}
.fade-up-line.show-element div:nth-child(20) {
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1) 1023ms,
    670ms top cubic-bezier(0.165, 0.84, 0.44, 1) 1023ms;
}
