.project-template .line {
  margin-right: 1.563vw;
}
@media (max-width: 1150px) {
  .project-template .line {
    margin-right: 1.739vw;
  }
}
@media (max-width: 900px) {
  .project-template .line {
    margin-right: 2.222vw;
  }
}
@media (max-width: 700px) {
  .project-template .line {
    margin-right: 16px;
  }
}
.project-template .template-hero {
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: -1;
  min-height: 59.565vw;
}
@media (max-width: 900px) {
  .project-template .template-hero {
    min-height: 67.222vw;
  }
}
@media (max-width: 700px) {
  .project-template .template-hero {
    min-height: 605px;
  }
}
@media (max-width: 700px) {
  .project-template .template-hero .container-narrow {
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
  }
}
.project-template .template-hero .subtitle {
  transition-delay: 500ms;
  margin-bottom: 1.953vw;
}
@media (max-width: 1150px) {
  .project-template .template-hero .subtitle {
    margin-bottom: 2.174vw;
  }
}
@media (max-width: 900px) {
  .project-template .template-hero .subtitle {
    margin-bottom: 2.778vw;
  }
}
@media (max-width: 700px) {
  .project-template .template-hero .subtitle {
    margin-bottom: 25px;
  }
}
.project-template .template-hero h1 {
  transition-delay: 580ms;
}
.project-template .template-hero .category {
  display: flex;
  flex-wrap: wrap;
  gap: 1.563vw;
  margin-top: 2.578vw;
}
.project-template .template-hero .category p {
  font-size: 1.094vw;
  border-radius: 2.5vw;
  padding: 0.859vw 1.641vw;
  border: 1px solid rgba(255, 255, 255, 0.43);
}
@media (max-width: 1150px) {
  .project-template .template-hero .category p {
    font-size: 1.043vw;
    border-radius: 2.783vw;
    padding: 0.696vw 1.391vw;
    border: 1px solid rgba(255, 255, 255, 0.43);
  }
}
@media (max-width: 900px) {
  .project-template .template-hero .category p {
    font-size: 1.333vw;
    border-radius: 3.556vw;
    padding: 0.889vw 1.778vw;
    border: 1px solid rgba(255, 255, 255, 0.43);
  }
}
@media (max-width: 700px) {
  .project-template .template-hero .category {
    gap: 15px;
    margin-top: 30px;
  }
  .project-template .template-hero .category p {
    font-size: 12px;
    border-radius: 32px;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.43);
  }
}
.project-template .img-wrapper {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  transform: translateY(0) !important;
}
.project-template .img-wrapper img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 700px) {
  .project-template .img-wrapper img {
    height: 100% !important;
  }
}
.project-template .project-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 700px) {
  .project-template .project-info {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.project-template .project-info .wrapper {
  display: flex;
  flex-direction: column;
}
.project-template .project-info .subtitle {
  margin-bottom: 1.25vw;
}
@media (max-width: 1150px) {
  .project-template .project-info .subtitle {
    margin-bottom: 1.391vw;
  }
}
@media (max-width: 900px) {
  .project-template .project-info .subtitle {
    margin-bottom: 1.778vw;
  }
}
@media (max-width: 700px) {
  .project-template .project-info .subtitle {
    order: 3;
    margin-bottom: 0px;
    margin-top: 35px;
  }
}
.project-template .project-info .subtitle .opacity {
  opacity: 0.5;
}
.project-template .project-info .paragraph {
  display: flex;
  flex-direction: column;
  gap: 1.016vw;
}
@media (max-width: 1150px) {
  .project-template .project-info .paragraph {
    gap: 1.13vw;
  }
}
@media (max-width: 900px) {
  .project-template .project-info .paragraph {
    gap: 1.111vw;
  }
}
@media (max-width: 700px) {
  .project-template .project-info .paragraph {
    gap: 10px;
  }
}
.project-template .project-info .project-button {
  display: flex;
  margin-top: 3.125vw;
  gap: 0.781vw;
  cursor: pointer;
}
@media (max-width: 1150px) {
  .project-template .project-info .project-button {
    margin-top: 3.478vw;
    gap: 0.87vw;
  }
}
@media (max-width: 900px) {
  .project-template .project-info .project-button {
    margin-top: 4.444vw;
    gap: 1.111vw;
  }
}
@media (max-width: 700px) {
  .project-template .project-info .project-button {
    margin-top: 40px;
    gap: 10;
  }
}
@media (max-width: 375px) {
  .project-template .project-info .project-button {
    margin-top: 30px;
  }
}
.project-template .project-info .project-button svg {
  width: 1.172vw;
  fill: white;
}
@media (max-width: 1150px) {
  .project-template .project-info .project-button svg {
    width: 1.304vw;
  }
}
@media (max-width: 900px) {
  .project-template .project-info .project-button svg {
    width: 1.444vw;
  }
}
@media (max-width: 700px) {
  .project-template .project-info .project-button svg {
    width: 13px;
  }
}
.project-template .blog-info {
  grid-template-columns: 1fr !important;
}
.project-template .blog-info h2 {
  margin-bottom: 2.261vw;
}
.project-template .image-group {
  display: grid;
  gap: 3.125vw;
}
.project-template .image-group img {
  pointer-events: none;
  width: 100%;
}
@media (max-width: 600px) {
  .project-template .image-group {
    grid-template-columns: 1fr !important;
    margin: 0 !important;
  }
}
.project-template .image-space {
  margin-top: 3.125vw !important;
  margin-bottom: 3.125vw !important;
}
.project-template .animate-element {
  opacity: 0;
  transform: translateY(50px);
  transition: 670ms opacity cubic-bezier(0.165, 0.84, 0.44, 1), 670ms transform cubic-bezier(0.165, 0.84, 0.44, 1);
}
.project-template .show-element {
  opacity: 1;
  transform: translateY(0px);
}