.render-award {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.578vw;
}
@media (max-width: 1150px) {
  .render-award {
    grid-column-gap: 2.696vw;
  }
}
@media (max-width: 900px) {
  .render-award {
    grid-column-gap: 2.778vw;
  }
}
@media (max-width: 700px) {
  .render-award {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 28px;
  }
}
@media (max-width: 600px) {
  .render-award {
    grid-column-gap: 23px;
  }
}
@media (max-width: 450px) {
  .render-award {
    grid-column-gap: 27px;
    grid-template-columns: 1fr 1fr;
  }
}
.render-award img {
  width: 100%;
}
.render-award img:nth-child(even) {
  margin-top: 2.422vw;
}
@media (max-width: 1150px) {
  .render-award img:nth-child(even) {
    margin-top: 2.696vw;
  }
}
@media (max-width: 900px) {
  .render-award img:nth-child(even) {
    margin-top: 2.778vw;
  }
}
.render-award img:last-child {
  display: none;
}
@media (max-width: 700px) {
  .render-award img:nth-child(even) {
    margin-top: 0;
  }
  .render-award img:nth-child(3n+2) {
    margin-top: 28px;
  }
  .render-award img:last-child {
    display: initial;
  }
}
@media (max-width: 600px) {
  .render-award img:nth-child(3n+2) {
    margin-top: 23px;
  }
}
@media (max-width: 450px) {
  .render-award img:nth-child(3n+2) {
    margin-top: 0;
  }
  .render-award img:nth-child(even) {
    margin-top: 27px;
  }
  .render-award img:last-child {
    display: none;
  }
}

section.counter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 450px) {
  section.counter {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
section.counter .item {
  display: flex;
  flex-direction: column;
}
section.counter .item:nth-child(2) {
  align-items: center;
}
section.counter .item:nth-child(3) {
  align-items: flex-end;
}
section.counter .item .wrapper {
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
@media (max-width: 450px) {
  section.counter .item .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: right;
    align-items: center;
  }
}
section.counter .item .wrapper .number {
  font-size: 9.688vw;
  line-height: 107.9%;
  letter-spacing: -0.015rem;
  font-weight: 200;
}
@media (max-width: 1150px) {
  section.counter .item .wrapper .number {
    font-size: 10.087vw;
  }
}
@media (max-width: 900px) {
  section.counter .item .wrapper .number {
    font-size: 10.444vw;
  }
}
@media (max-width: 700px) {
  section.counter .item .wrapper .number {
    font-size: 13.429vw;
  }
}
@media (max-width: 600px) {
  section.counter .item .wrapper .number {
    font-size: 12.5vw;
  }
}
@media (max-width: 450px) {
  section.counter .item .wrapper .number {
    font-size: 22.889vw;
  }
}
@media (max-width: 375px) {
  section.counter .item .wrapper .number {
    font-size: 24vw;
  }
}
section.counter .item .wrapper .text {
  line-height: 145.9%;
}
@media (max-width: 1150px) {
  section.counter .item .wrapper .text {
    font-size: 1.391vw;
  }
}
@media (max-width: 900px) {
  section.counter .item .wrapper .text {
    font-size: 1.556vw;
  }
}
@media (max-width: 700px) {
  section.counter .item .wrapper .text {
    font-size: 14px;
    max-width: 130px;
  }
}
@media (max-width: 450px) {
  section.counter .item .wrapper .text {
    font-size: 16px;
  }
}

.logos {
  padding: 11.719vw 0;
}
@media (max-width: 1150px) {
  .logos {
    padding: 13.043vw 0;
  }
}
@media (max-width: 900px) {
  .logos {
    padding: 16.667vw 0;
  }
}
@media (max-width: 700px) {
  .logos {
    padding: 120px 0;
  }
}
@media (max-width: 450px) {
  .logos {
    padding: 100px 0;
  }
}
@media (max-width: 375px) {
  .logos {
    padding: 55px 0;
  }
}
.logos h2 {
  max-width: 35.391vw;
  margin-bottom: 6.25vw;
}
@media (max-width: 1150px) {
  .logos h2 {
    max-width: 39.391vw;
    margin-bottom: 6.957vw;
  }
}
@media (max-width: 900px) {
  .logos h2 {
    max-width: 50.333vw;
    margin-bottom: 7.778vw;
  }
}
@media (max-width: 700px) {
  .logos h2 {
    max-width: 453px;
    margin-bottom: 50px;
  }
}
.logos .view {
  display: grid;
  grid-template-columns: 1fr 1fr 0px 1fr 1fr;
  grid-column-gap: 2.891vw;
  grid-row-gap: 5.859vw;
  grid-auto-rows: 10.156vw;
}
@media (max-width: 1150px) {
  .logos .view {
    grid-template-columns: 1fr 1fr 0.957vw 1fr 1fr;
    grid-column-gap: 2.783vw;
    grid-row-gap: 6.522vw;
    grid-auto-rows: 10.696vw;
  }
}
@media (max-width: 900px) {
  .logos .view {
    grid-template-columns: 1fr 1fr 0.87vw 1fr 1fr;
    grid-column-gap: 2.174vw;
    grid-row-gap: 4.783vw;
    grid-auto-rows: 8.348vw;
  }
}
@media (max-width: 700px) {
  .logos .view {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 18px;
    grid-auto-rows: 96px;
  }
  .logos .view .blank {
    display: none;
  }
}
@media (max-width: 600px) {
  .logos .view {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 14px;
    grid-column-gap: 60px;
  }
}
@media (max-width: 450px) {
  .logos .view {
    grid-column-gap: 30px;
  }
}
@media (max-width: 375px) {
  .logos .view {
    grid-column-gap: 20px;
  }
}
.logos .view .item {
  display: flex;
}
.logos .view .item svg {
  height: 100%;
}
.logos .view .item2,
.logos .view .item3,
.logos .view .item6,
.logos .view .item7,
.logos .view .item10,
.logos .view .item11 {
  justify-content: center;
}
.logos .view .item4,
.logos .view .item8,
.logos .view .item12 {
  justify-content: flex-end;
}
@media (max-width: 700px) {
  .logos .view .item4,
  .logos .view .item7,
  .logos .view .item10 {
    justify-content: initial;
  }
  .logos .view .item2,
  .logos .view .item5,
  .logos .view .item8,
  .logos .view .item11 {
    justify-content: center;
  }
  .logos .view .item3,
  .logos .view .item6,
  .logos .view .item9,
  .logos .view .item12 {
    justify-content: right;
  }
}
@media (max-width: 600px) {
  .logos .view .item1 {
    justify-content: center;
  }
  .logos .view .item2 {
    justify-content: center;
  }
  .logos .view .item3 {
    justify-content: center;
  }
  .logos .view .item4 {
    justify-content: center;
  }
  .logos .view .item5 {
    justify-content: center;
  }
  .logos .view .item6 {
    justify-content: center;
  }
  .logos .view .item7 {
    justify-content: center;
  }
  .logos .view .item8 {
    justify-content: center;
  }
  .logos .view .item9 {
    justify-content: center;
  }
  .logos .view .item10 {
    justify-content: center;
  }
  .logos .view .item11 {
    justify-content: center;
  }
  .logos .view .item12 {
    justify-content: center;
  }
}

.team {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ". ." "location .";
  grid-column-gap: 5.859vw;
  grid-row-gap: 2.109vw;
}
@media (max-width: 1150px) {
  .team {
    grid-column-gap: 4.348vw;
    grid-row-gap: 2.609vw;
  }
}
@media (max-width: 900px) {
  .team {
    grid-column-gap: 5.556vw;
    grid-row-gap: 2.556vw;
  }
}
@media (max-width: 700px) {
  .team {
    grid-template-columns: 1fr;
    grid-template-areas: "." "." "." "location";
    grid-column-gap: 0;
    grid-row-gap: 25px;
  }
}
@media (max-width: 700px) {
  .team h2 {
    margin-bottom: 25px;
    max-width: 345px;
  }
}
.team .members {
  display: flex;
  flex-direction: column;
  gap: 2.109vw;
}
@media (max-width: 1150px) {
  .team .members {
    gap: 2.609vw;
  }
}
@media (max-width: 900px) {
  .team .members {
    gap: 2.556vw;
  }
}
@media (max-width: 700px) {
  .team .members {
    gap: 25px;
  }
}
.team .members .team-item {
  display: flex;
  flex-direction: column;
  gap: 0.781vw;
}
@media (max-width: 1150px) {
  .team .members .team-item {
    gap: 0.87vw;
  }
}
@media (max-width: 900px) {
  .team .members .team-item {
    gap: 1.444vw;
  }
}
@media (max-width: 700px) {
  .team .members .team-item {
    gap: 13px;
  }
}
.team .members .team-item .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team .members .team-item .line .name {
  font-size: 2.188vw;
  font-weight: 200;
  line-height: 145.9%;
  letter-spacing: -0.015rem;
}
@media (max-width: 1150px) {
  .team .members .team-item .line .name {
    font-size: 2.435vw;
  }
}
@media (max-width: 900px) {
  .team .members .team-item .line .name {
    font-size: 2.667vw;
  }
}
@media (max-width: 700px) {
  .team .members .team-item .line .name {
    font-size: 24px;
  }
}
.team .members .team-item .line svg {
  width: 2.109vw;
}
@media (max-width: 1150px) {
  .team .members .team-item .line svg {
    width: 2.348vw;
  }
}
@media (max-width: 900px) {
  .team .members .team-item .line svg {
    width: 3vw;
  }
}
@media (max-width: 700px) {
  .team .members .team-item .line svg {
    width: 27px;
  }
}
.team .members .team-item .line .position {
  opacity: 0.5;
  line-height: 145.9%;
}
.team .members .team-item .line .country {
  opacity: 0.5;
}
.team .located {
  grid-area: location;
  align-self: flex-end;
  display: flex;
  gap: 1.563vw;
  align-items: center;
}
@media (max-width: 1150px) {
  .team .located {
    gap: 1.739vw;
  }
}
@media (max-width: 900px) {
  .team .located {
    gap: 1.889vw;
  }
}
@media (max-width: 700px) {
  .team .located {
    gap: 17px;
    margin-top: 25px;
    display: none;
  }
}
.team .located .opacity {
  opacity: 0.5;
}
.team .located .icon {
  background-color: #fff;
  border-radius: 50%;
  height: 3.516vw;
  width: 3.516vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team .located .icon svg {
  width: 33%;
}
@media (max-width: 1150px) {
  .team .located .icon {
    height: 3.913vw;
    width: 3.913vw;
  }
}
@media (max-width: 900px) {
  .team .located .icon {
    height: 4.222vw;
    width: 4.222vw;
  }
}
@media (max-width: 700px) {
  .team .located .icon {
    height: 38px;
    width: 38px;
  }
}

.full-image {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 900px) {
  .full-image {
    height: 600px;
  }
}
.full-image img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.full-image .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.406vw;
}
@media (max-width: 1150px) {
  .full-image .content {
    gap: 1.565vw;
  }
}
@media (max-width: 900px) {
  .full-image .content {
    gap: 1.667vw;
  }
}
@media (max-width: 700px) {
  .full-image .content {
    gap: 15px;
  }
}
.full-image .content h2 {
  max-width: 56.25vw;
}
@media (max-width: 1150px) {
  .full-image .content h2 {
    max-width: 58.087vw;
  }
}
@media (max-width: 900px) {
  .full-image .content h2 {
    max-width: 60.778vw;
  }
}
@media (max-width: 700px) {
  .full-image .content h2 {
    max-width: 465px;
  }
}

.faq h2 {
  margin-bottom: 5.469vw;
  width: 80%;
}
@media (max-width: 1150px) {
  .faq h2 {
    margin-bottom: 6.087vw;
  }
}
@media (max-width: 900px) {
  .faq h2 {
    margin-bottom: 7.778vw;
    width: 100%;
  }
}
@media (max-width: 700px) {
  .faq h2 {
    margin-bottom: 50px;
  }
}
.faq .faq-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
}
@media (max-width: 1150px) {
  .faq .faq-wrapper {
    gap: 2.174vw;
  }
}
@media (max-width: 900px) {
  .faq .faq-wrapper {
    gap: 2.778vw;
  }
}
@media (max-width: 700px) {
  .faq .faq-wrapper {
    gap: 25px;
  }
}
.faq .faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 2.5vw;
}
@media (max-width: 1150px) {
  .faq .faq-item {
    padding-bottom: 2.174vw;
  }
}
@media (max-width: 900px) {
  .faq .faq-item {
    padding-bottom: 2.778vw;
  }
}
@media (max-width: 700px) {
  .faq .faq-item {
    padding-bottom: 25px;
  }
}
.faq .faq-item .visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq .faq-item .visible .question {
  line-height: 145.9%;
  font-size: 1.875vw;
  width: 70%;
}
@media (max-width: 1150px) {
  .faq .faq-item .visible .question {
    font-size: 2.087vw;
    width: 75%;
  }
}
@media (max-width: 900px) {
  .faq .faq-item .visible .question {
    font-size: 2.444vw;
    width: 82%;
  }
}
@media (max-width: 700px) {
  .faq .faq-item .visible .question {
    font-size: 22px;
    width: 90%;
  }
}
.faq .faq-item .visible .icon-wrapper {
  width: 1.172vw;
  transition: 0.5s ease;
  height: -moz-max-content;
  height: max-content;
}
@media (max-width: 1150px) {
  .faq .faq-item .visible .icon-wrapper {
    width: 1.217vw;
  }
}
@media (max-width: 900px) {
  .faq .faq-item .visible .icon-wrapper {
    width: 1.556vw;
  }
}
@media (max-width: 700px) {
  .faq .faq-item .visible .icon-wrapper {
    width: 14px;
  }
}
.faq .faq-item .visible .icon-wrapper svg {
  width: 100%;
  fill: #fff;
}
.faq .faq-item .margin-top {
  margin-top: 1.016vw;
}
@media (max-width: 1150px) {
  .faq .faq-item .margin-top {
    margin-top: 1.13vw;
  }
}
@media (max-width: 900px) {
  .faq .faq-item .margin-top {
    margin-top: 1.333vw;
  }
}
@media (max-width: 700px) {
  .faq .faq-item .margin-top {
    margin-top: 10px;
  }
}
.faq .faq-item .answer {
  overflow: hidden;
  transition: 0.5s ease;
  width: 70%;
  opacity: 0.5;
}
@media (max-width: 1150px) {
  .faq .faq-item .answer {
    width: 75%;
  }
}
@media (max-width: 900px) {
  .faq .faq-item .answer {
    width: 82%;
  }
}
@media (max-width: 700px) {
  .faq .faq-item .answer {
    width: 90%;
  }
}
.faq .faq-item:nth-child(1) {
  transition-delay: 260ms !important;
}
.faq .faq-item:nth-child(2) {
  transition-delay: 300ms !important;
}
.faq .faq-item:nth-child(3) {
  transition-delay: 340ms !important;
}
.faq .faq-item:nth-child(4) {
  transition-delay: 380ms !important;
}
.faq .faq-item:nth-child(5) {
  transition-delay: 420ms !important;
}
.faq .faq-item:nth-child(6) {
  transition-delay: 460ms !important;
}
.faq .faq-item:nth-child(7) {
  transition-delay: 500ms !important;
}
.faq .faq-item:nth-child(8) {
  transition-delay: 540ms !important;
}
.faq .faq-item:nth-child(9) {
  transition-delay: 580ms !important;
}
.faq .faq-item:nth-child(10) {
  transition-delay: 620ms !important;
}